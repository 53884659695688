import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

const JLP = 'jlp';
const organisationToHostURLMap = {
  [JLP]: window.NutmegConfig.AUTH_HOST_JLP
};

const useHashParamsOrgRedirect = () => {
  const { hash } = useLocation();
  const { org } = queryString.parse(hash);
  const redirectURL = organisationToHostURLMap[org];

  return { redirectURL };
};

export default useHashParamsOrgRedirect;
