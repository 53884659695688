import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { AuthProvider } from '@nm-utils-lib-web/authentication';
import { IMPERSONATION_MODE } from '@nm-utils-lib-web/authentication/src/constants/featureDetection';
import cookies from 'browser-cookies';
import { getCookieDomain } from '@nm-utils-lib-web/cookies';

import { ROUTES } from '../../constants';
import { HandleCallback } from '../HandleCallback';

import CustomerLogin from './CustomerLogin';

const clearExistingAuthCookies = () => {
  const domain = getCookieDomain();

  cookies.erase(IMPERSONATION_MODE, { domain });
};

const Customer = () => {
  clearExistingAuthCookies();

  return (
    <AuthProvider shouldIgnoreAuth0FeatureDetection>
      <Switch>
        <Route exact path={ROUTES.CUSTOMER_LOGIN} component={CustomerLogin} />
        <Route
          data-qa="auth-web-customer-login-callback-route"
          exact
          path={ROUTES.CUSTOMER_CALLBACK}
          render={props => <HandleCallback {...props} />}
        />
      </Switch>
    </AuthProvider>
  );
};

export default Customer;
