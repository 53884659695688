import { organisationHeaderTranslations } from '@nm-ui-shared-lib-web/organisation-header/languages/nutmeg';
import { organisationFooterTranslations } from '@nm-ui-shared-lib-web/organisation-footer/languages/nutmeg';

import impersonateStart from './impersonateStart.json';
import error from './error.json';
import verifyEmail from './verifyEmail.json';

export default {
  auth: {
    common: {
      ...organisationHeaderTranslations,
      ...organisationFooterTranslations,
      appTitle: 'Nutmeg',
      impersonateStart,
      error,
      verifyEmail
    }
  }
};
