// eslint-disable-next-line max-len
import { organisationFooterTranslations } from '@nm-ui-shared-lib-web/organisation-footer/languages/johnlewisfinance-gbr';

import banner from './banner.json';

export default {
  auth: {
    common: {
      ...organisationFooterTranslations
    },
    ...banner
  }
};
