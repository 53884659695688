import handleError from "./errorHandler";
import AuthenticationService from './AuthenticationService';

const postPasswordReset = async ({ email }) => {
  try {
    await AuthenticationService.post('/users/password', { email });
  } catch (error) {
    handleError(error, 'Error resetting password');
  }
};

export default postPasswordReset;
