import React from 'react';
import { string, node, func, number, oneOfType } from 'prop-types';
import { Heading, headingLevels } from '@nutkit/component-text';
import Notification, { notificationLevels } from '@nutkit/component-notification';
import ButtonGroup, { buttonGroupAlignments } from '@nutkit/component-button-group';
import Link, { buttonCtas } from '@nutkit/component-link';
import Button, { buttonCtaTypes } from '@nutkit/component-button';

// Mirroring ErrorSplash component until MVP is out
// TODO: Amend ErrorSplash button conditional in Nutkit and remove this mirrored component once MVP is out
const ErrorSplash = ({ title, subtitle, status, ctaText, ctaPath, onCtaClick, children, ...otherProps }) => {
  return (
    <section data-qa="nk-error-splash" {...otherProps}>
      <Heading data-qa="nk-error-splash__title">{title}</Heading>
      {subtitle && (
        <Heading level={headingLevels.TWO} data-qa="nk-error-splash__subtitle">
          {subtitle}
        </Heading>
      )}
      <Notification dismissable={false} level={notificationLevels.ERROR}>
        {status ? `${status} : ${children}` : children}
      </Notification>
      {(onCtaClick || ctaPath) && (
        <ButtonGroup align={buttonGroupAlignments.RIGHT} stackUp>
          {onCtaClick ? (
            <Button cta={buttonCtaTypes.PRIMARY} data-qa="nk-error-splash__button" onClick={onCtaClick}>
              {ctaText}
            </Button>
          ) : (
            <Link button buttonCta={buttonCtas.PRIMARY} href={ctaPath}>
              {ctaText}
            </Link>
          )}
        </ButtonGroup>
      )}
    </section>
  );
};

ErrorSplash.propTypes = {
  title: string.isRequired,
  subtitle: string,
  status: oneOfType([string, number]),
  ctaText: string,
  ctaPath: string,
  onCtaClick: func,
  children: node.isRequired
};

ErrorSplash.defaultProps = {
  subtitle: null,
  status: null,
  ctaText: '',
  ctaPath: '',
  onCtaClick: null
};

export { ErrorSplash as default };
