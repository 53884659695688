import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { AuthProvider } from '@nm-utils-lib-web/authentication';

import { ROUTES } from '../../constants';

import Logout from './Logout';

const LogoutRoutes = () => {
  return (
    <AuthProvider shouldIgnoreAuth0FeatureDetection>
      <Switch>
        <Route data-qa="auth-web-logout-route" path={ROUTES.LOGOUT} component={Logout} />
      </Switch>
    </AuthProvider>
  );
};

export default LogoutRoutes;
