const isPrivateScope = ({ scope }) => scope === 'private';
const isPublicScope = ({ scope }) => scope === 'public';
const getCustomerPasswordRules = ({ validationRules }) => validationRules.customerValidationRules.passwordRules;
const getPublicCustomerPasswordRules = ({ validationRules }) =>
  getCustomerPasswordRules({ validationRules }).filter(isPublicScope);
const getPrivateCustomerPasswordRules = ({ validationRules }) =>
  getCustomerPasswordRules({ validationRules }).filter(isPrivateScope);
const getPrivateCustomerUsernameRules = ({ validationRules }) => 
  validationRules.customerValidationRules.usernameRules.filter(isPrivateScope);

;

export { getPublicCustomerPasswordRules, getPrivateCustomerPasswordRules, getPrivateCustomerUsernameRules };
