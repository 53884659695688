import React from 'react';
import { node } from 'prop-types';
import { Layout as NutkitLayout } from '@nutkit/layouts';

import Header from '../Header';
import Footer from '../Footer';

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <NutkitLayout>{children}</NutkitLayout>
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: node.isRequired
};

export default Layout;
