import { useState, useEffect } from 'react';
import cookies from 'browser-cookies';
import { isUseAuth0, featureDetection } from '@nm-utils-lib-web/authentication';
import { useFlag, Flags } from '@nm-utils-lib-web/flags';
import { Login } from '@nm-utils-lib-web/routes';

const appSLOCookies = [
  { cookieName: featureDetection.DASHBOARD_SLO_COOKIE, url: window.NutmegConfig.DASHBOARD_HOST },
  { cookieName: featureDetection.SIGNUP_SLO_COOKIE, url: window.NutmegConfig.SIGNUP_HOST }
];

const useAppLogout = () => {
  const customerUniversalLoginEnabled = useFlag(Flags.CUSTOMER_UNIVERSAL_LOGIN);
  const auth0LoginLocalstorageEnabled = useFlag(Flags.CUSTOMER_UNIVERSAL_LOGIN_LOCALSTORAGE);
  const [allAppsLoggedOut, setAllAppsLoggedOut] = useState(false);

  useEffect(() => {
    const appsNotLoggedOut = appSLOCookies.filter(app => !cookies.get(app.cookieName));
    const appLogoutIsComplete = appsNotLoggedOut.length === 0;

    if (!appLogoutIsComplete && customerUniversalLoginEnabled && auth0LoginLocalstorageEnabled && isUseAuth0()) {
      appsNotLoggedOut.map(app => window.location.replace(app.url + Login.LOGOUT_PATH));
    } else {
      setAllAppsLoggedOut(true);
    }
  }, [customerUniversalLoginEnabled, auth0LoginLocalstorageEnabled, allAppsLoggedOut]);

  return { allAppsLoggedOut };
};

export default useAppLogout;
