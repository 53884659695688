import { useState, useEffect } from 'react';

import { getCustomerValidationRules } from '../services';
import { getPrivateCustomerUsernameRules } from '../utils';
import handleError from '../services/errorHandler';

const useGetPrivateCustomerUsernameRules = () => {
  const [emailRules, setEmailRules] = useState([]);

  useEffect(() => {
    const fetchEmailRules = async () => {
      await getPrivateCustomerUsername();
    };

    fetchEmailRules();
  }, []);

  const getPrivateCustomerUsername = async () => {
    try {
      const validationRules = await getCustomerValidationRules();

      setEmailRules(getPrivateCustomerUsernameRules(validationRules));
    } catch (error) {
      handleError(error, 'Error getting email rules');
    }
  };

  return { emailRules };
};

export default useGetPrivateCustomerUsernameRules;
