import React from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import VerifyEmailFailure from '../VerifyEmailFailure';
import VerifyEmailSuccess from '../VerifyEmailSuccess';
import { useHashParamsOrgRedirect } from '../../hooks';

const VerifyEmail = () => {
  const { search } = useLocation();
  const { redirectURL } = useHashParamsOrgRedirect();
  const { success } = queryString.parse(search);

  if (redirectURL) {
    window.location.replace(`${redirectURL}/verify-email${search || ''}`);

    return null;
  }

  if (success === 'true') {
    return <VerifyEmailSuccess />;
  }

  return <VerifyEmailFailure />;
};

export default VerifyEmail;
