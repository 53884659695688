import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { getLoginReturnToParams } from '@nm-utils-lib-web/routes/utils';

const useQueryParamsReturnToRedirect = () => {
  const { search } = useLocation();
  const { returnTo } = queryString.parse(search);
  const [shouldReturnTo, returnToURL] = getLoginReturnToParams(returnTo);

  return { returnTo: shouldReturnTo ? returnToURL : undefined };
};

export default useQueryParamsReturnToRedirect;
