import React from 'react';
import { node } from 'prop-types';
import { Container, Row, Col } from '@nutkit/component-grid';
import { useFlag, Flags } from '@nm-utils-lib-web/flags';
import { useLoginBanner, CONFIGURATION_SOURCES } from '@nm-ui-shared-lib-web/login-banner';
import { isJohnLewisFinanceGBR } from '@nm-utils-lib-web/organisations';

import Header from '../Header';
import Footer from '../Footer';
import JlfBanner from '../JlfBanner';

const getBannerSource = interactionStudioFlag =>
  interactionStudioFlag ? CONFIGURATION_SOURCES.INTERACTION_STUDIO : CONFIGURATION_SOURCES.PRISMIC;

const LayoutWithBanner = ({ children }) => {
  const interactionStudioFlag = useFlag(Flags.INTERACTION_STUDIO_BANNER);
  const { Banner } = useLoginBanner(getBannerSource(interactionStudioFlag));

  return (
    <>
      <Header />
      <Container className="nm-login-container" fluid>
        <Row className="nm-login-container__wrapper">
          <Col className="nm-login-container__left" md={{ size: 6 }} sm={{ size: 4 }}>
            {isJohnLewisFinanceGBR() ? <JlfBanner /> : <Banner className="nm-login__banner" />}
          </Col>
          <Col
            className="nm-login-container__right"
            sm={{ size: 10, offset: 1 }}
            md={{ size: 6, offset: 0 }}
            xl={{ size: 4, offset: 1 }}
          >
            {children}
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

LayoutWithBanner.propTypes = {
  children: node.isRequired
};

export default LayoutWithBanner;
