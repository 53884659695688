import React from 'react';
import { bool, func } from 'prop-types';
import { Trans, useTranslation } from '@nm-utils-lib-web/translations';
import { Heading, headingLevels, Text } from '@nutkit/component-text';
import ButtonGroup, { buttonGroupAlignments } from '@nutkit/component-button-group';
import Button, { buttonCtaTypes } from '@nutkit/component-button';
import Notification, { notificationLevels } from '@nutkit/component-notification';
import { pageView } from '@nm-utils-lib-web/analytics';
import Panel from '@nutkit/component-panel';

import Layout from '../Layout';
import { TRACKING } from '../../constants';

const TRANSLATION_NAMESPACE = 'auth.common.verifyEmail';

const VerifyEmailExpired = ({ isLoading, error, onClick }) => {
  const { t } = useTranslation();

  React.useEffect(() => {
    pageView({
      name: TRACKING.EMAIL_VERIFICATION.EXPIRED
    });
  }, []);

  return (
    <Layout>
      <Heading>{t(`${TRANSLATION_NAMESPACE}.expired.title`)}</Heading>
      <Panel>
        <Heading data-qa="email-verification-expired-title" level={headingLevels.TWO}>
          {t(`${TRANSLATION_NAMESPACE}.expired.subTitle`)}
        </Heading>
        <Trans
          components={[<Text>Text</Text>, <Text>Text</Text>, <Text>Text</Text>]}
          i18nKey={`${TRANSLATION_NAMESPACE}.expired.message`}
        />
        {error && (
          <Notification data-qa="verification-resend-error" level={notificationLevels.ERROR} dismissable={false}>
            {t(`${TRANSLATION_NAMESPACE}.resendError`)}
          </Notification>
        )}
        <ButtonGroup align={buttonGroupAlignments.RIGHT}>
          <Button
            cta={buttonCtaTypes.PRIMARY}
            data-qa="resend-verification-button"
            isLoading={isLoading}
            onClick={onClick}
          >
            {t(`${TRANSLATION_NAMESPACE}.resendEmail`)}
          </Button>
        </ButtonGroup>
      </Panel>
    </Layout>
  );
};

VerifyEmailExpired.propTypes = {
  error: bool,
  isLoading: bool,
  onClick: func.isRequired
};

VerifyEmailExpired.defaultProps = {
  error: false,
  isLoading: false
};

export default VerifyEmailExpired;
