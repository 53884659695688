import React from 'react';
import { string, shape } from 'prop-types';
import { iconColors, IconClose, IconTick } from '@nutkit/component-icon';
import { List, ListItem } from '@nutkit/component-list';
import { Text, textSizes } from '@nutkit/component-text';
import { useTranslation } from '@nm-utils-lib-web/translations';

import { validationRulesShape } from '../../constants/propTypeConstants';

const TRANSLATION_NAMESPACE = 'login.newPasswordCheckList';

// eslint-disable-next-line react/prop-types
const PasswordListItem = ({ isComplete, message, allRulesCompleted, ...props }) => {
  const { t } = useTranslation();
  const completedItemProps = {
    icon: props => <IconTick {...props} aria-label={t(`${TRANSLATION_NAMESPACE}.completedItem`)} />,
    bulletColor: iconColors.SUCCESS
  };
  const itemProps = isComplete ? completedItemProps : { ...props };

  return (
    <ListItem {...itemProps} data-qa="rule-list-item" aria-hidden={allRulesCompleted}>
      {message}
    </ListItem>
  );
};

const NewPasswordCheckList = ({ password, passwordRules, translations, id }) => {
  const { t } = useTranslation();
  const allRulesCompleted = passwordRules.every(({ pattern }) => pattern.test(password));

  return (
    <>
      <Text id={`${id}MustHave`} textSize={textSizes.XS} aria-hidden={allRulesCompleted}>
        {t(translations.passwordMustHave)}
      </Text>

      <List
        id={id}
        size="xs"
        icon={props => <IconClose {...props} aria-label={t(`${TRANSLATION_NAMESPACE}.uncompletedItem`)} />}
        data-qa="rule-list"
        aria-label={allRulesCompleted ? 'All password requirements are met' : undefined}
        aria-live={allRulesCompleted ? 'assertive' : 'off'}
      >
        {passwordRules.map(({ message, pattern }) => (
          <PasswordListItem
            key={message}
            isComplete={pattern.test(password)}
            message={message}
            allRulesCompleted={allRulesCompleted}
          />
        ))}
      </List>
    </>
  );
};

NewPasswordCheckList.propTypes = {
  password: string.isRequired,
  passwordRules: validationRulesShape.isRequired,
  translations: shape({
    passwordMustHave: string.isRequired
  }),
  id: string
};

NewPasswordCheckList.defaultProps = {
  id: 'NewPasswordCheckList',
  translations: {
    passwordMustHave: `${TRANSLATION_NAMESPACE}.passwordMustHave`
  }
};

export default NewPasswordCheckList;
