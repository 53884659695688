import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useInitialiseAnalytics } from '@nm-utils-lib-web/analytics';
import { apps } from '@nm-utils-lib-web/monitoring';
import { useTranslation } from '@nm-utils-lib-web/translations';
import { useMaintenanceMode } from '@nm-utils-lib-web/maintenance';
import { useFlag, Flags } from '@nm-utils-lib-web/flags';

import Impersonate from '../Impersonate';
import Customer from '../Customer';
import LogoutRoutes from '../Logout';
import VerifyEmail from '../VerifyEmail';
import NewPasswordPage from '../NewPasswordPage';
import PasswordSentPage from '../PasswordSentPage';
import ResetPasswordPage from '../ResetPasswordPage';
import { GenericError } from '../GenericError';
import { ROUTES } from '../../constants';

const App = () => {
  const { t } = useTranslation();
  const isMaintenanceModeFlagEnabled = useFlag(Flags.MAINTENANCE_MODE);

  useInitialiseAnalytics({ appName: apps.NM_AUTH_WEB });
  useMaintenanceMode(isMaintenanceModeFlagEnabled);

  return (
    <>
      <Helmet>
        <title>{t('global.common.organisation.appTitle')}</title>
      </Helmet>
      <Switch>
        <Route path={ROUTES.IMPERSONATE} component={Impersonate} />
        <Route path={ROUTES.CUSTOMER_LOGIN} component={Customer} />
        <Route path={ROUTES.LOGOUT} component={LogoutRoutes} />
        <Route path={ROUTES.VERIFY_EMAIL} component={VerifyEmail} />
        <Route path={ROUTES.ERROR} component={GenericError} />
        <Route path={ROUTES.RESET_PASSWORD_PATH} component={ResetPasswordPage} />
        <Route path={ROUTES.RESET_PASSWORD_SENT_PATH} component={PasswordSentPage} />
        <Route path={ROUTES.NEW_PASSWORD_PATH} component={NewPasswordPage} />
        <Redirect exact from={ROUTES.HOME} to={ROUTES.CUSTOMER_LOGIN} />
        <Redirect path="*" to={ROUTES.CUSTOMER_LOGIN} />
      </Switch>
    </>
  );
};

export default App;
