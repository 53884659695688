export const messages = {
  INVALID_SECURITY_CODE: 'Invalid security code',
  PASSWORDS_DO_NOT_MATCH: 'Passwords do not match',
  REQUIRED_FIELD: 'This field is required'
};
const getFirstValidationError = (value, rules) =>
  rules.find(({ pattern, scope }) => {
    const result = pattern.test(value);

    // Because the BE returns regexs that evaluate differently based on whether they're 'private' or 'public'
    return scope === 'private' ? result : !result;
  });

const validateEmailField = (email, emailRules) => {
  if (!email) {
    return { email: messages.REQUIRED_FIELD };
  }

  if (emailRules) {
    const emailRuleError = getFirstValidationError(email, emailRules);

    if (emailRuleError) {
      return { email: emailRuleError.message };
    }
  }

  return {};
};

const validatePasswordField = (password, rules) => {
  if (!password) {
    return { password: messages.REQUIRED_FIELD };
  }

  if (rules) {
    const passwordRuleError = getFirstValidationError(password, rules);

    if (passwordRuleError) {
      return { password: passwordRuleError.message };
    }
  }
};

const validateConfirmPasswordField = (password, confirmPassword) => {
  if (!confirmPassword) {
    return { confirmPassword: messages.REQUIRED_FIELD };
  }

  if (password !== confirmPassword) {
    return { confirmPassword: messages.PASSWORDS_DO_NOT_MATCH };
  }
};

export const validateNewPassword = passwordRules => ({ password, confirmPassword }) => ({
  ...validatePasswordField(password, passwordRules),
  ...validateConfirmPasswordField(password, confirmPassword)
});

export const validateResetPassword = emailRules => ({ email }) => validateEmailField(email, emailRules);
