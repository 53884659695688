import React, { useEffect } from 'react';
import { Login as LoginRoutes } from '@nm-utils-lib-web/routes';
import { ResetPassword } from '@nm-auth/password-reset';
import { useHistory } from 'react-router-dom';
import { pageView } from '@nm-utils-lib-web/analytics';

import LayoutWithBanner from '../LayoutWithBanner';

const ResetPasswordPage = () => {
  const history = useHistory();

  useEffect(() => {
    pageView({
      name: 'ResetPassword_Page_Viewed'
    });
  }, []);

  return (
    <LayoutWithBanner>
      <ResetPassword
        backButtonPath={LoginRoutes.LOGIN_PATH}
        onResetSuccess={() => history.push(LoginRoutes.RESET_PASSWORD_SENT_PATH)}
      />
    </LayoutWithBanner>
  );
};

export default ResetPasswordPage;
