import cookies from 'browser-cookies';
import jwtDecode from 'jwt-decode';
import { getCookieDomain } from '@nm-utils-lib-web/cookies';

const setTemporaryAccessTokenCookie = accessToken => {
  const { exp } = jwtDecode(accessToken);
  const expiryTime = new Date(exp * 1000);

  cookies.set('access_token', accessToken, { expires: expiryTime, domain: getCookieDomain(), secure: true });
};

export default setTemporaryAccessTokenCookie;
