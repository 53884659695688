import React from 'react';
import { string } from 'prop-types';
import ButtonGroup from '@nutkit/component-button-group';
import Link, { buttonCtas } from '@nutkit/component-link';
import Panel from '@nutkit/component-panel';
import { Heading, Text, headingLevels, textSizes, textAligns } from '@nutkit/component-text';
import { useTranslation } from '@nm-utils-lib-web/translations';

import TroubleSigningInLink from '../TroubleSigningInLink';

const TRANSLATION_NAMESPACE = 'login.passwordSent';

const PasswordSent = ({ backButtonPath }) => {
  const { t } = useTranslation();

  
return (
    <>
      <Heading>{t(`${TRANSLATION_NAMESPACE}.heading`)}</Heading>
      <Panel>
        <Heading level={headingLevels.TWO} textSize={textSizes.MD}>
          {t(`${TRANSLATION_NAMESPACE}.subHeading`)}
        </Heading>
        <Text>{t(`${TRANSLATION_NAMESPACE}.emailWithInstructions`)}</Text>
        <ButtonGroup stackUp>
          <Link isInternal button buttonCta={buttonCtas.SECONDARY} href={backButtonPath} data-qa="back">
            {t(`${TRANSLATION_NAMESPACE}.navigation.back`)}
          </Link>
        </ButtonGroup>
      </Panel>
      <Text textAlign={textAligns.CENTER}>
        <TroubleSigningInLink />
      </Text>
    </>
  );
};

PasswordSent.propTypes = {
  backButtonPath: string.isRequired
};

export default PasswordSent;
