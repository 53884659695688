import React from 'react';
import { linkSizes } from '@nutkit/component-link';
import { SupportArticles as SupportArticlesRoutes } from '@nm-utils-lib-web/routes';
import { LinkWithTracking } from '@nm-utils-lib-web/analytics';
import { useTranslation } from '@nm-utils-lib-web/translations';

const TRANSLATION_NAMESPACE = 'login.helpLinks';

const TroubleSigningInLink = () => {
  const { t } = useTranslation();

  return (
    <LinkWithTracking
      href={SupportArticlesRoutes.TROUBLE_SIGNING_IN_URL}
      size={linkSizes.SM}
      isExternal
      data-qa="trouble-signing-in"
      eventPayload={{ name: 'Login_Trouble_Signing_In' }}
    >
      {t(`${TRANSLATION_NAMESPACE}.havingTrouble`)}
    </LinkWithTracking>
  );
};

export default TroubleSigningInLink;
