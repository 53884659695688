import useSWR from 'swr';
import { getSWRIsLoading } from '@nm-utils-lib-web/network/helpers';

import { getCustomerValidationRules } from '../services';
import { getPrivateCustomerPasswordRules, getPublicCustomerPasswordRules } from '../utils';

const useGetCustomerPasswordRules = (options = {}) => {
  const { data, error } = useSWR('getCustomerValidationRules', getCustomerValidationRules, {
    revalidateOnFocus: false,
    ...options
  });
  const privatePasswordRules = data ? getPrivateCustomerPasswordRules(data) : [];
  const publicPasswordRules = data ? getPublicCustomerPasswordRules(data) : [];
  const isLoading = getSWRIsLoading(data, error);

  return { publicPasswordRules, privatePasswordRules, isLoading, error };
};

export default useGetCustomerPasswordRules;
