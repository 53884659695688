import { transformValidationRules } from '../utils';

import handleError from "./errorHandler";
import AuthenticationService from './AuthenticationService';

const getCustomerValidationRules = async () => {
  try {
    const { data } = await AuthenticationService.get('/tokens/customer_validation_metadata');
    const transformedPasswordRules = {
      validationRules: {
        customerValidationRules: transformValidationRules(data)
      }
    };

    return transformedPasswordRules;
  } catch (error) {
    handleError(error, 'Error getting customer validation rules');
  }
};

export default getCustomerValidationRules;
