import React from 'react';
import { OrganisationHeader } from '@nm-ui-shared-lib-web/organisation-header';
import ThemeProvider, { themeNames } from '@nutkit/theme';

const TRANSLATION_NAMESPACE = 'auth.common.header';

const Header = () => {
  return (
    <ThemeProvider themeName={themeNames.DARK}>
      <OrganisationHeader
        translationNameSpace={TRANSLATION_NAMESPACE}
        logoHref={window.NutmegConfig.MARKETING_HOST}
        noStack
      />
    </ThemeProvider>
  );
};

export default Header;
