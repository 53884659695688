import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { auth0 } from '@nm-utils-lib-web/authentication';
/*
 * PAR-1069
 * Auth0 only allow one URL on the 'return to login' CTA at the end of the password
 * reset flow. The default is the Nutmeg authentication URL. This function checks for an
 * organisation param in the URL and returns a URL if the parameter is present. This URL
 * can be used to redirect a user to the correct organisation login.
 */

const organisationToHostURLMap = {
  [auth0.ORG_JLF_DEV]: window.NutmegConfig.AUTH_HOST_JLP,
  [auth0.ORG_JLF_PROD]: window.NutmegConfig.AUTH_HOST_JLP
};

const useQueryParamsOrgRedirect = () => {
  const { search } = useLocation();
  const { organization } = queryString.parse(search);
  const redirectURL = organisationToHostURLMap[organization];

  return { redirectURL };
};

export default useQueryParamsOrgRedirect;
