const pollOneTrustAcceptedCookie = isOneTrustEnabled => {
  return new Promise(resolve => {
    if (isOneTrustEnabled && window.NutmegConfig?.ONE_TRUST_ID) {
      const interval = setInterval(() => {
        const hasAcceptedOnetrust = window.OneTrust && window.OneTrust.IsAlertBoxClosedAndValid();

        if (hasAcceptedOnetrust) {
          resolve(true);
          clearInterval(interval);
        }
      }, 500);
    } else {
      resolve(true);
    }
  });
};

export default pollOneTrustAcceptedCookie;
