import merge from 'lodash.merge';
import Translations, { getCommonTranslations } from '@nm-utils-lib-web/translations';
import { getOrganisation, ORGANISATIONS } from '@nm-utils-lib-web/organisations';
import { nutmeg as nutmegLogin } from '@nm-auth/password-reset/languages';

import {
  nutmeg as nutmegAuth,
  johnlewisfinancegbr as johnLewisFinanceAuth,
  chasegbr as chaseAuth
} from '../languages';

export const nutmegTranslations = {
  ...nutmegAuth,
  ...nutmegLogin,
  ...getCommonTranslations(ORGANISATIONS.NUTMEG_GBR_BIGBEAR)
};

export const johnLewisTranslations = {
  ...merge({}, nutmegAuth, johnLewisFinanceAuth),
  ...nutmegLogin,
  ...getCommonTranslations(ORGANISATIONS.JOHN_LEWIS_FINANCE_GBR)
};

export const chaseTranslations = {
  ...merge({}, nutmegAuth, chaseAuth),
  ...nutmegLogin,
  ...getCommonTranslations(ORGANISATIONS.CHASE_GBR)
};

const i18n = new Translations({
  language: 'en',
  defaultNS: getOrganisation(),
  resources: {
    en: {
      default: {
        ...nutmegAuth,
        ...nutmegLogin,
        ...getCommonTranslations(ORGANISATIONS.NUTMEG_GBR_BIGBEAR)
      },
      [ORGANISATIONS.NUTMEG_GBR_BIGBEAR]: nutmegTranslations,
      [ORGANISATIONS.JOHN_LEWIS_FINANCE_GBR]: johnLewisTranslations,
      [ORGANISATIONS.CHASE_GBR]: chaseTranslations
    }
  }
});

export default i18n;
