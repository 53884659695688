export const HOME = '/';
export const IMPERSONATE = '/impersonate';
export const IMPERSONATE_CALLBACK = '/impersonate/login/callback';
export const IMPERSONATE_START = '/impersonate/start';
export const CUSTOMER_LOGIN = '/login';
export const CUSTOMER_CALLBACK = '/login/callback';
export const LOGOUT = '/logout';
export const ERROR = '/error';
export const VERIFY_EMAIL = '/verify-email';
export const RESET_PASSWORD_PATH = '/reset-password';
export const RESET_PASSWORD_SENT_PATH = '/reset-password-sent';
export const NEW_PASSWORD_PATH = '/new-password';
