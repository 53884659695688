import React, { useEffect } from 'react';
import { Login as LoginRoutes } from '@nm-utils-lib-web/routes';
import { NewPassword } from '@nm-auth/password-reset';
import { useHistory } from 'react-router-dom';
import { pageView } from '@nm-utils-lib-web/analytics';

import LayoutWithBanner from '../LayoutWithBanner';

const NewPasswordPage = () => {
  const history = useHistory();

  useEffect(() => {
    pageView({
      name: 'NewPassword_Page_Viewed'
    });
  }, []);

  return (
    <LayoutWithBanner>
      <NewPassword onResetPasswordSuccess={() => history.push(LoginRoutes.LOGIN_PATH)} />
    </LayoutWithBanner>
  );
};

export default NewPasswordPage;
