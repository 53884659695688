import React from 'react';
import { OrganisationFooter } from '@nm-ui-shared-lib-web/organisation-footer';
import ThemeProvider, { themeNames } from '@nutkit/theme';

const TRANSLATION_NAMESPACE = 'auth.common.footer';

const Footer = () => {
  return (
    <ThemeProvider themeName={themeNames.DARK}>
      <OrganisationFooter translationNameSpace={TRANSLATION_NAMESPACE} noStack />
    </ThemeProvider>
  );
};

export default Footer;
