import { axios, axiosWithSentryMiddleware } from '@nm-utils-lib-web/axios-custom-middleware';

const DEFAULT_TIMEOUT = 30000;
const AuthenticationService = axiosWithSentryMiddleware(
  axios.create({
    baseURL: global.NutmegConfig.NM_AUTHENTICATION_SERVICE,
    timeout: DEFAULT_TIMEOUT
  })
);

export default AuthenticationService;
