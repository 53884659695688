/*
 * Auth0 return errors as URL parameters, pulling out these
 * parameters mean we can raise better errors in Sentry
 */
const getErrorQuery = () => {
  const search = new URLSearchParams(window.location.search);
  const error = search.get('error');
  const description = search.get('error_description') || '';

  if (!error && description) {
    return new Error(description);
  }

  if (!error) {
    return;
  }

  return new Error(error, { cause: description });
};

export default getErrorQuery;
