import Auth0GatewayService from './Auth0GatewayService';
import handleError from './errorHandler';

const postVerificationEmail = async ({ email }) => {
  try {
    await Auth0GatewayService.post('/users/verification_email', { email });
  } catch (error) {
    handleError(error, 'Error resending a verficiation email');
  }
};

export default postVerificationEmail;
