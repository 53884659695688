import useSWR from 'swr';
import { getSWRIsLoading, neverRetryOn404 } from '@nm-utils-lib-web/network/helpers';

import getAnnualReviewState from '../services/getAnnualReviewState';

export const useGetAnnualReviewState = ({ customerUuid, shouldMakeRequest = true, onError = () => {} } = {}) => {
  const cacheKey = `getAnnualReviewState/${customerUuid}`;
  const { data, error } = useSWR(shouldMakeRequest ? cacheKey : null, () => getAnnualReviewState(customerUuid), {
    revalidateOnFocus: false,
    onErrorRetry: neverRetryOn404,
    onError
  });
  const isLoading = getSWRIsLoading(data, error, shouldMakeRequest);

  return {
    data,
    isLoading,
    error
  };
};
