import React from 'react';
import { useAuth, useGetAuthenticationMetadata, eraseSLOCookie } from '@nm-utils-lib-web/authentication';
import { Customer } from '@nm-utils-lib-web/routes';
import { useVariant, Variants, Flags } from '@nm-utils-lib-web/flags';
import { useTranslation } from '@nm-utils-lib-web/translations';
import Loading from '@nutkit/component-loading';
import { identify, event, useIdentifyWithUnleashExperiments } from '@nm-utils-lib-web/analytics';
import { useGetJourneysHome } from '@nm-customer/journeys/hooks';

import { GenericError } from '../GenericError';
import { useQueryParamsReturnToRedirect } from '../../hooks';
import { isAnnualReviewInGracePeriod } from '../../helpers';
import { useGetAnnualReviewState } from '../../hooks/useGetAnnualReviewState';

import setTemporaryAccessTokenCookie from './setTemporaryAccessTokenCookie';

const TRANSLATION_NAMESPACE = 'auth.common.error';
const { DASHBOARD_HOST } = global.NutmegConfig;

const HandleCallback = () => {
  const { isLoading, isAuthenticated, error, getAccessTokenSilently } = useAuth();
  const { customerUuid } = useGetAuthenticationMetadata();
  const identifyWithUnleashExperiment = useIdentifyWithUnleashExperiments();
  const { returnTo } = useQueryParamsReturnToRedirect();
  const { url, error: journeyError } = useGetJourneysHome(customerUuid, {
    returnTo
  });
  const skippableReviewPromptVariant = useVariant(Flags.SKIPPABLE_REVIEW_PROMPT);
  const isSkippableReviewPromptEnabled =
    skippableReviewPromptVariant.enabled && skippableReviewPromptVariant.name === Variants.VARIANT_B;
  const {
    isLoading: isAnnualReviewDataLoading,
    data: annualReviewData,
    error: annualReviewError
  } = useGetAnnualReviewState({
    customerUuid,
    shouldMakeRequest: Boolean(isSkippableReviewPromptEnabled && customerUuid)
  });
  const { t } = useTranslation();

  const loginErrorHandler = async () => {
    await event({
      name: 'Web_Auth_Login_Error',
      options: {
        category: 'Login'
      }
    });
  };

  React.useEffect(() => {
    const loginSuccessHandler = async () => {
      await identify({ userId: customerUuid });

      await event({
        name: 'Web_Auth_Login_Success',
        options: {
          category: 'Login'
        }
      });

      await identifyWithUnleashExperiment(customerUuid);

      // temporarily set access token cookie
      // TODO: Remove once Ruby UL is complete: https://hungryfinance.jira.com/browse/PAR-1088
      const accessToken = await getAccessTokenSilently();

      setTemporaryAccessTokenCookie(accessToken);

      if (
        url === DASHBOARD_HOST &&
        isSkippableReviewPromptEnabled &&
        isAnnualReviewInGracePeriod(annualReviewData.annualReviewState)
      ) {
        const CUSTOMER_REVIEW_ACCOUNT_URL = `${DASHBOARD_HOST}${Customer.CUSTOMER_REVIEW_ACCOUNT_PATH}`;

        return window.location.replace(CUSTOMER_REVIEW_ACCOUNT_URL);
      }

      return window.location.replace(url);
    };

    eraseSLOCookie();

    if (!isLoading && !isAnnualReviewDataLoading && isAuthenticated && customerUuid && url) {
      loginSuccessHandler();
    }
    // Disable eslint to stop getLoginStep being added as a dep which causes a callback loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    customerUuid,
    isLoading,
    isAuthenticated,
    url,
    annualReviewData,
    isSkippableReviewPromptEnabled,
    isAnnualReviewDataLoading
  ]);

  if (error || journeyError || annualReviewError || (!isLoading && !isAuthenticated)) {
    loginErrorHandler();

    const customError = new Error('Unhandled login callback error');

    customError.extra = { referrer: window.document.referrer };

    return (
      <GenericError
        error={error || journeyError || annualReviewError || customError}
        ctaText={t(`${TRANSLATION_NAMESPACE}.cta`)}
        ctaPath="/logout"
      />
    );
  }

  return <Loading data-qa="handlecallback-loader" />;
};

export default HandleCallback;
