import handleError from "./errorHandler";
import AuthenticationService from './AuthenticationService';

const putNewPasswordReset = async ({ token, password }) => {
  try {
    const { data } = await AuthenticationService.put('/users/password', { token, password });

    
return data;
  } catch (error) {
    handleError(error, 'Error updating password');
  }
};

export default putNewPasswordReset;
