import React from 'react';
import { useTranslation } from '@nm-utils-lib-web/translations';
import { Heading, headingLevels, Text } from '@nutkit/component-text';
import Animation, { animations } from '@nutkit/component-animation';
import ButtonGroup, { buttonGroupAlignments } from '@nutkit/component-button-group';
import Link, { buttonCtas } from '@nutkit/component-link';
import Panel from '@nutkit/component-panel';
import { pageView } from '@nm-utils-lib-web/analytics';

import Layout from '../Layout';
import { TRACKING, ROUTES } from '../../constants';

const TRANSLATION_NAMESPACE = 'auth.common.verifyEmail';
const { animationSuccess } = animations;

const VerifyEmailSuccess = () => {
  const { t } = useTranslation();

  React.useEffect(() => {
    pageView({
      name: TRACKING.EMAIL_VERIFICATION.SUCCESSFUL
    });
  }, []);

  return (
    <Layout>
      <Heading>{t(`${TRANSLATION_NAMESPACE}.title`)}</Heading>
      <Panel>
        <Heading level={headingLevels.TWO}>{t(`${TRANSLATION_NAMESPACE}.verified.title`)}</Heading>
        <Animation size="lg" data={animationSuccess} data-qa="verification-successful-animation" />
        <Heading level={headingLevels.THREE}>{t(`${TRANSLATION_NAMESPACE}.verified.allDone`)}</Heading>
        <Text>{t(`${TRANSLATION_NAMESPACE}.verified.message`)}</Text>
        <ButtonGroup align={buttonGroupAlignments.RIGHT}>
          <Link button buttonCta={buttonCtas.PRIMARY} href={ROUTES.LOGOUT} data-qa="continue-verification-button">
            {t(`${TRANSLATION_NAMESPACE}.continue`)}
          </Link>
        </ButtonGroup>
      </Panel>
    </Layout>
  );
};

export default VerifyEmailSuccess;
