import React, { useEffect } from 'react';
import { Login as LoginRoutes } from '@nm-utils-lib-web/routes';
import { PasswordSent } from '@nm-auth/password-reset';
import { pageView } from '@nm-utils-lib-web/analytics';

import LayoutWithBanner from '../LayoutWithBanner';

const PasswordSentPage = () => {
  useEffect(() => {
    pageView({
      name: 'PasswordSent_Page_Viewed'
    });
  }, []);

  return (
    <LayoutWithBanner>
      <PasswordSent backButtonPath={LoginRoutes.LOGIN_PATH} />
    </LayoutWithBanner>
  );
};

export default PasswordSentPage;
