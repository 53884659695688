import { useState } from 'react';

import { requestStatuses } from '../constants';
import { postPasswordReset } from '../services';
import handleError from '../services/errorHandler';

const usePostPasswordReset = () => {
  const [resetPasswordStatus, setResetPasswordStatus] = useState('');

  const sendPasswordReset = async ({ email }) => {
    setResetPasswordStatus(requestStatuses.REQUESTED);

    try {
      await postPasswordReset({ email });
      setResetPasswordStatus(requestStatuses.SUCCESS);
    } catch (error) {
      setResetPasswordStatus(requestStatuses.FAILURE);
      handleError(error, 'Error resetting password');
    }
  };

  const unsetResetPasswordStatus = () => {
    setResetPasswordStatus('');
  };

  return { sendPasswordReset, resetPasswordStatus, unsetResetPasswordStatus };
};

export default usePostPasswordReset;
