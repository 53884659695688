import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { event } from '@nm-utils-lib-web/analytics';
import { monitor } from '@nm-utils-lib-web/monitoring';

import { GenericError } from '../GenericError';
import VerifyEmailAccountUnverified from '../VerifyEmailAccountUnverified';
import VerifyEmailExpired from '../VerifyEmailExpired';
import VerifyEmailResent from '../VerifyEmailResent';
import { TRACKING } from '../../constants';
import { usePostVerificationEmail } from '../../hooks';

const { EMAIL_VERIFICATION } = TRACKING;

const VerifyEmailFailure = () => {
  const { search } = useLocation();
  const { email: emailParam, id: idParam } = queryString.parse(search);
  const [email, setEmail] = useState('');
  const { resendVerificationEmail, isLoading, error, success: hasResentEmail } = usePostVerificationEmail({
    onError: errorResponse => {
      event({
        name: EMAIL_VERIFICATION.VERIFICATION_EMAIL_RESEND_FAILED,
        properties: {
          category: 'Onboarding'
        }
      });
      monitor.captureException(errorResponse);
    }
  });

  const emailResendOnClickHandler = email => {
    event({
      name: EMAIL_VERIFICATION.VERIFICATION_EMAIL_RESEND_CLICK,
      properties: {
        category: 'Onboarding'
      }
    });
    resendVerificationEmail({ email });
  };

  if (hasResentEmail) {
    return <VerifyEmailResent email={email} />;
  }

  if (emailParam) {
    return (
      <VerifyEmailExpired
        onClick={() => {
          const decodedEmail = decodeURIComponent(emailParam);

          setEmail(decodedEmail);
          emailResendOnClickHandler(decodedEmail);
        }}
        isLoading={isLoading}
        error={!!error}
      />
    );
  }

  if (idParam) {
    return (
      <VerifyEmailAccountUnverified
        onClick={() => {
          const decodedEmail = window.atob(decodeURIComponent(idParam));

          setEmail(decodedEmail);
          emailResendOnClickHandler(decodedEmail);
        }}
        isLoading={isLoading}
        error={!!error}
      />
    );
  }

  return <GenericError />;
};

export default VerifyEmailFailure;
