import React from 'react';
import { func, string } from 'prop-types';
import Notification, { notificationLevels } from '@nutkit/component-notification';
import Panel from '@nutkit/component-panel';
import { Heading, Text, headingLevels, textAligns } from '@nutkit/component-text';
import { useTranslation } from '@nm-utils-lib-web/translations';

import ResetPasswordForm from '../ResetPasswordForm';
import TroubleSigningInLink from '../TroubleSigningInLink';
import { usePostPasswordReset, useGetPrivateCustomerUsernameRules } from '../../hooks';
import { requestStatuses } from '../../constants';

const TRANSLATION_NAMESPACE = 'login.resetPassword';

const ResetPassword = ({ onResetSuccess, backButtonPath }) => {
  const { t } = useTranslation();
  const { sendPasswordReset, resetPasswordStatus, unsetResetPasswordStatus } = usePostPasswordReset();
  const { emailRules } = useGetPrivateCustomerUsernameRules();

  if (resetPasswordStatus === requestStatuses.SUCCESS) {
    onResetSuccess();

    
return null;
  }

  return (
    <>
      <Heading>{t(`${TRANSLATION_NAMESPACE}.heading`)}</Heading>
      {resetPasswordStatus === requestStatuses.FAILURE && (
        <Notification level={notificationLevels.ERROR} data-qa="password-reset-error">
          {t(`${TRANSLATION_NAMESPACE}.notAbleResetPassword`)}
        </Notification>
      )}
      <Panel>
        <Heading level={headingLevels.TWO}>{t(`${TRANSLATION_NAMESPACE}.subHeading`)}</Heading>
        <Text>{t(`${TRANSLATION_NAMESPACE}.enterTheEmail`)}</Text>
        <ResetPasswordForm
          backButtonPath={backButtonPath}
          sendPasswordReset={sendPasswordReset}
          resetPasswordStatus={resetPasswordStatus}
          unsetResetPasswordStatus={unsetResetPasswordStatus}
          emailRules={emailRules}
        />
      </Panel>
      <Text textAlign={textAligns.CENTER}>
        <TroubleSigningInLink />
      </Text>
    </>
  );
};

ResetPassword.propTypes = {
  onResetSuccess: func.isRequired,
  backButtonPath: string.isRequired
};

export default ResetPassword;
