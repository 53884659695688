import { useState } from 'react';

import { postVerificationEmail } from '../services';

const usePostVerificationEmail = ({ onError } = {}) => {
  const [state, setState] = useState({
    error: null,
    isLoading: false,
    success: false
  });
  const { error, isLoading, success } = state;

  const handleError = error => {
    setState(state => ({
      ...state,
      error,
      isLoading: false
    }));

    onError && onError(error);
  };

  const handleSuccess = () => {
    setState(state => ({
      ...state,
      isLoading: false,
      success: true
    }));
  };

  const resendVerificationEmail = async ({ email }) => {
    setState(state => ({ ...state, isLoading: true, error: null }));

    try {
      await postVerificationEmail({ email });

      handleSuccess();
    } catch (error) {
      handleError(error);
    }
  };

  return { resendVerificationEmail, isLoading, success, error };
};

export default usePostVerificationEmail;
