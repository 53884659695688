import { useState } from 'react';

import { passwordStates } from '../constants';
import { putNewPasswordReset } from '../services';

const usePasswordReset = () => {
  const [passwordState, setPasswordState] = useState(passwordStates.IDLE);

  const putNewPasswordRequest = async ({ token, password }) => {
    setPasswordState(passwordStates.LOADING);

    try {
      await putNewPasswordReset({ token, password });
      setPasswordState(passwordStates.SUCCESS);
    } catch (error) {
      setPasswordState(passwordStates.FAILURE);
    }
  };

  const resetNewPasswordState = () => {
    setPasswordState(passwordStates.IDLE);
  };

  return { passwordState, putNewPasswordRequest, resetNewPasswordState };
};

export default usePasswordReset;
