const mapValidationRuleProperties = ({ pattern, ...otherProperties }) => ({
  pattern: new RegExp(pattern),
  ...otherProperties
});

export const transformValidationRules = ({ validation_metadata }) => {
  return validation_metadata.reduce(
    (validationRules, { field, policies }) => ({
      ...validationRules,
      [`${field}Rules`]: policies.map(mapValidationRuleProperties)
    }),
    {}
  );
};
