import React, { useEffect } from 'react';
import { func, string } from 'prop-types';
import { Formik, Field as FormikField } from 'formik';
import { InputField } from '@nm-ui-shared-lib-web/form';
import { event } from '@nm-utils-lib-web/analytics';
import Button from '@nutkit/component-button';
import ButtonGroup from '@nutkit/component-button-group';
import Link, { buttonCtas } from '@nutkit/component-link';
import { useTranslation } from '@nm-utils-lib-web/translations';

import { requestStatuses, validationRulesShape } from '../../constants';
import { validateResetPassword } from '../../utils';

const TRANSLATION_NAMESPACE = 'login.resetPasswordForm';

const ResetPasswordForm = ({
  sendPasswordReset,
  resetPasswordStatus,
  unsetResetPasswordStatus,
  emailRules,
  backButtonPath
}) => {
  const { t } = useTranslation();

  const onSubmitHandler = ({ email }) => {
    event({
      name: 'New_Login_Password_Reset_Requested'
    });
    sendPasswordReset({ email });
  };

  useEffect(() => {
    return () => unsetResetPasswordStatus();
  }, []);

  return (
    <Formik initialValues={{ email: '' }} onSubmit={onSubmitHandler} validate={validateResetPassword(emailRules)}>
      {({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit} data-qa="reset-password-form">
          <FormikField
            id="email"
            type="email"
            name="email"
            translationNamespace={TRANSLATION_NAMESPACE}
            label={t(`${TRANSLATION_NAMESPACE}.fields.email.label`)}
            placeholder={t(`${TRANSLATION_NAMESPACE}.fields.email.placeholder`)}
            data-qa="email"
            component={InputField}
          />
          <ButtonGroup stackUp stackOnMobile>
            <Link isInternal href={backButtonPath} button buttonCta={buttonCtas.SECONDARY} data-qa="back">
              {t(`${TRANSLATION_NAMESPACE}.navigation.back`)}
            </Link>
            <Button
              type="submit"
              disabled={!values.email}
              isLoading={resetPasswordStatus === requestStatuses.REQUESTED}
              data-qa="submit"
            >
              {t(`${TRANSLATION_NAMESPACE}.navigation.continue`)}
            </Button>
          </ButtonGroup>
        </form>
      )}
    </Formik>
  );
};

ResetPasswordForm.propTypes = {
  backButtonPath: string.isRequired,
  sendPasswordReset: func.isRequired,
  unsetResetPasswordStatus: func.isRequired,
  resetPasswordStatus: string.isRequired,
  emailRules: validationRulesShape.isRequired
};

export default ResetPasswordForm;
