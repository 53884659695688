import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { AuthProvider, eraseSLOCookie } from '@nm-utils-lib-web/authentication';

import { ROUTES } from '../../constants';
import { HandleCallback } from '../HandleCallback';
import ImpersonateStart from '../ImpersonateStart';

import ImpersonateLogin from './ImpersonateLogin';

const Impersonate = () => {
  eraseSLOCookie();

  return (
    <AuthProvider shouldIgnoreAuth0FeatureDetection shouldIgnoreImpersonationFeatureDetection>
      <Switch>
        <Route
          data-qa="nm-auth-impersonate-start-route"
          path={ROUTES.IMPERSONATE_START}
          exact
          component={ImpersonateStart}
        />
        <Route data-qa="nm-auth-impersonate-route" exact path={ROUTES.IMPERSONATE} component={ImpersonateLogin} />
        <Route
          data-qa="nm-auth-impersonate-callback-login-route"
          exact
          path={ROUTES.IMPERSONATE_CALLBACK}
          render={props => <HandleCallback {...props} />}
        />
      </Switch>
    </AuthProvider>
  );
};

export default Impersonate;
