import { ORGANISATIONS } from '@nm-utils-lib-web/organisations/constants';

export const NUTMEG = 'nutmeg';
export const JOHN_LEWIS_FINANCE = 'jlp';
export const CHASE = 'chase-gbr';

export const AUTH_HOSTS = {
  [ORGANISATIONS.JOHN_LEWIS_FINANCE_GBR]: 'AUTH_HOST_JLP',
  [ORGANISATIONS.NUTMEG_GBR_BIGBEAR]: 'AUTH_HOST_NUTMEG',
  [ORGANISATIONS.CHASE_GBR]: 'AUTH_HOST_CHASE_GBR'
};
