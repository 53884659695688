import React from 'react';
import PropTypes from 'prop-types';
import { monitor } from '@nm-utils-lib-web/monitoring';
import { CONTACT_URL } from '@nm-utils-lib-web/routes/supportArticles';
import { Trans, useTranslation } from '@nm-utils-lib-web/translations';
import { Container } from '@nutkit/component-grid';
import Link from '@nutkit/component-link';

import Header from '../Header';
import Footer from '../Footer';
import ErrorSplash from '../ErrorSplash';
import { getErrorQuery } from '../../helpers';

const TRANSLATION_NAMESPACE = 'auth.common.error';

const GenericError = ({ error, enableMonitoring, translationNamespace, ctaText, ctaPath, children }) => {
  const { t } = useTranslation();

  React.useEffect(() => {
    const monitorError = getErrorQuery() || error || new Error('Error');

    enableMonitoring && monitor.captureException(monitorError);
  }, [enableMonitoring, error]);

  return (
    <>
      <Header />
      <Container>
        <ErrorSplash title={t(`${translationNamespace}.title`)} ctaText={ctaText} ctaPath={ctaPath}>
          {children || (
            <Trans
              components={[<Link href="/logout">content</Link>, <Link href={CONTACT_URL}>content</Link>]}
              i18nKey={`${translationNamespace}.message`}
            />
          )}
        </ErrorSplash>
      </Container>
      <Footer />
    </>
  );
};

GenericError.propTypes = {
  error: PropTypes.instanceOf(Error),
  enableMonitoring: PropTypes.bool,
  translationNamespace: PropTypes.string,
  ctaText: PropTypes.string,
  ctaPath: PropTypes.string,
  children: PropTypes.node
};

GenericError.defaultProps = {
  error: null,
  enableMonitoring: true,
  translationNamespace: TRANSLATION_NAMESPACE,
  ctaText: '',
  ctaPath: '',
  children: null
};

export default GenericError;
