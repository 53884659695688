import React from 'react';
import Align, { textAlignments } from '@nutkit/component-align';
import Image from '@nutkit/component-image';
import fetchImage, { imageSizes } from '@nm-marketing/prismic-utils';
import { Trans, useTranslation } from '@nm-utils-lib-web/translations';
import { Row, Col } from '@nutkit/component-grid';
import { Text, Heading, textStyles, textWeights, headingLevels } from '@nutkit/component-text';

//TODO: Refactor so the JLF LoginBanner isn't hardcoded into the login app  https://hungryfinance.jira.com/browse/PAR-1870
const TRANSLATION_NAMESPACE = 'auth.banner';

const JlfBanner = () => {
  const { t } = useTranslation();
  const imageSource = fetchImage(
    'johnlewis',
    '93ef7337-77b7-410b-9bbd-495adbe4ded1_isa-invest',
    imageSizes.AUTO,
    'png',
    {
      fit: 'clip'
    }
  );

  return (
    <div className="nm-login-banner__wrapper nm-login__banner" style={{ backgroundColor: '#fff' }}>
      <Row>
        <Col md={{ size: 10, offset: 1 }} lg={{ size: 8, offset: 2 }} xl={{ size: 6, offset: 3 }}>
          <Align xs={{ textAlign: textAlignments.CENTER }}>
            <Heading level={headingLevels.TWO} textStyle={textStyles.TITLE_1}>
              <Trans t={t} i18nKey={`${TRANSLATION_NAMESPACE}.title`} components={[<br />]} />
            </Heading>
          </Align>
        </Col>
      </Row>
      <Row>
        <Col md={{ size: 10, offset: 1 }} lg={{ size: 8, offset: 2 }} xl={{ size: 6, offset: 3 }}>
          <Align xs={{ textAlign: textAlignments.CENTER }}>
            <Text textStyle={textStyles.TEXT_1} textWeight={textWeights.MEDIUM}>
              {t(`${TRANSLATION_NAMESPACE}.subtitle`)}
            </Text>
            <Text textStyle={textStyles.TEXT_1}>{t(`${TRANSLATION_NAMESPACE}.riskWarning`)}</Text>
          </Align>
        </Col>
      </Row>
      {/* minHeight required for ie11: https://github.com/philipwalton/flexbugs/issues/75#issuecomment-134702421 */}
      <Row style={{ minHeight: '1px' }}>
        <Col md={{ size: 10, offset: 1 }} lg={{ size: 8, offset: 2 }} xl={{ size: 6, offset: 3 }}>
          <Align xs={{ textAlign: textAlignments.CENTER }}>
            <Image src={imageSource} alt="" />
          </Align>
        </Col>
      </Row>
    </div>
  );
};

export default JlfBanner;
