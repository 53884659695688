import React from 'react';
import { string } from 'prop-types';
import { Heading, Text, headingLevels, textAligns, textWeights } from '@nutkit/component-text';
import Link from '@nutkit/component-link';
import Panel from '@nutkit/component-panel';
import { useTranslation, Trans } from '@nm-utils-lib-web/translations';
import Notification, { notificationLevels } from '@nutkit/component-notification';
import Bold from '@nutkit/component-bold';
import { SupportArticles } from '@nm-utils-lib-web/routes';

import Layout from '../Layout';

const TRANSLATION_NAMESPACE = 'auth.common.verifyEmail';

const VerifyEmailResent = ({ email }) => {
  const { t } = useTranslation();

  return (
    <Layout>
      <Heading>{t(`${TRANSLATION_NAMESPACE}.emailSent.title`)}</Heading>
      <Panel aria-live="polite">
        <Heading level={headingLevels.TWO}>{t(`${TRANSLATION_NAMESPACE}.emailSent.subTitle`, { email })}</Heading>
        {email && (
          <>
            <Text aria-live="polite">{t(`${TRANSLATION_NAMESPACE}.emailSent.sentTheEmail`, { email })}</Text>
            <Text textAlign={textAligns.CENTER} textWeight={textWeights.BOLD}>
              {email}
            </Text>
          </>
        )}
        <Text>
          {t(`${TRANSLATION_NAMESPACE}.emailSent.checkInbox`, { email })}
          <Bold weight={textWeights.BOLD}>{t(`${TRANSLATION_NAMESPACE}.emailSent.expirePeriod`, { email })}</Bold>
        </Text>
        <Notification level={notificationLevels.INFORMATION} dismissable={false}>
          {t(`${TRANSLATION_NAMESPACE}.emailSent.notification`)}
        </Notification>
        <Text>
          <Trans
            components={[
              <Link isExternal href={SupportArticles.CONTACT_FAQ_URL}>
                Link
              </Link>
            ]}
            i18nKey={`${TRANSLATION_NAMESPACE}.emailSent.notArrived`}
          />
        </Text>
      </Panel>
    </Layout>
  );
};

VerifyEmailResent.propTypes = {
  email: string.isRequired
};

export default VerifyEmailResent;
