import React, { useEffect } from 'react';
import { func, string, shape, bool } from 'prop-types';
import { Formik, Field as FormikField } from 'formik';
import { InputField } from '@nm-ui-shared-lib-web/form';
import Button from '@nutkit/component-button';
import ButtonGroup, { buttonGroupAlignments } from '@nutkit/component-button-group';
import { useTranslation } from '@nm-utils-lib-web/translations';
import Loading from '@nutkit/component-loading';

import { validateNewPassword } from '../../utils';
import NewPasswordCheckList from '../NewPasswordCheckList';
import { passwordStates } from '../../constants';
import { validationRulesShape } from '../../constants/propTypeConstants';

const TRANSLATION_NAMESPACE = 'login.newPasswordForm';

const NewPasswordForm = ({
  translations,
  dataQa,
  onSubmit,
  putNewPasswordRequest,
  resetNewPasswordState,
  token,
  publicPasswordRules,
  privatePasswordRules,
  passwordState,
  isLoading: isLoadingProp,
  isFetching
}) => {
  const { t } = useTranslation();
  const newPasswordChecklistId = 'newPasswordChecklist';

  useEffect(() => {
    return () => resetNewPasswordState();
  }, []);

  const handleSubmit = (...args) => {
    const [values, actions] = args;

    if (onSubmit) {
      return onSubmit(values);
    }

    putNewPasswordRequest({ ...values, token });

    actions.setSubmitting(false);
  };
  const isLoading = isLoadingProp !== undefined ? isLoadingProp : passwordState === passwordStates.LOADING;

  return (
    <Formik
      initialValues={{ password: '', confirmPassword: '' }}
      onSubmit={handleSubmit}
      validate={validateNewPassword(privatePasswordRules)}
    >
      {({ handleSubmit, values }) => {
        const { password, confirmPassword } = values;
        const isNewPasswordValid = password && publicPasswordRules.every(({ pattern }) => pattern.test(password));

        return (
          <form onSubmit={handleSubmit} data-qa={dataQa}>
            <FormikField
              id="password"
              type="password"
              name="password"
              aria-labelledby={`${newPasswordChecklistId}MustHave ${newPasswordChecklistId}`}
              translationNamespace={TRANSLATION_NAMESPACE}
              label={t(translations.passwordField.label)}
              placeholder={t(translations.passwordField.placeholder)}
              component={InputField}
              data-qa="new-password"
            />
            {isFetching ? (
              <Loading />
            ) : (
              <NewPasswordCheckList
                password={password}
                id={newPasswordChecklistId}
                passwordRules={publicPasswordRules}
                translations={translations.newPasswordCheckList}
                data-qa="new-password-check-list"
              />
            )}
            <FormikField
              id="confirmPassword"
              type="password"
              name="confirmPassword"
              translationNamespace={TRANSLATION_NAMESPACE}
              label={t(translations.confirmPasswordField.label)}
              placeholder={t(translations.confirmPasswordField.placeholder)}
              component={InputField}
              data-qa="confirm-password"
            />
            <ButtonGroup stackUp stackOnMobile align={buttonGroupAlignments.RIGHT}>
              <Button
                type="submit"
                disabled={!isNewPasswordValid || confirmPassword !== password}
                data-qa="submit"
                isLoading={isLoading}
              >
                {t(translations.continue)}
              </Button>
            </ButtonGroup>
          </form>
        );
      }}
    </Formik>
  );
};

NewPasswordForm.propTypes = {
  token: string,
  passwordState: string.isRequired,
  publicPasswordRules: validationRulesShape.isRequired,
  privatePasswordRules: validationRulesShape.isRequired,
  putNewPasswordRequest: func.isRequired,
  resetNewPasswordState: func.isRequired,
  onSubmit: func,
  translations: shape({
    passwordField: shape({ label: string.isRequired, placeholder: string.isRequired }).isRequired,
    confirmPasswordField: shape({ label: string.isRequired, placeholder: string.isRequired }).isRequired,
    passwordCheck: shape({
      passwordMustHave: string.isRequired
    }),
    continue: string
  }),
  isLoading: bool,
  isFetching: bool,
  dataQa: string
};

NewPasswordForm.defaultProps = {
  token: '',
  onSubmit: undefined,
  isFetching: false,
  translations: {
    passwordField: {
      label: `${TRANSLATION_NAMESPACE}.fields.password.label`,
      placeholder: `${TRANSLATION_NAMESPACE}.fields.password.placeholder`
    },
    confirmPasswordField: {
      label: `${TRANSLATION_NAMESPACE}.fields.confirmPassword.label`,
      placeholder: `${TRANSLATION_NAMESPACE}.fields.confirmPassword.placeholder`
    },
    continue: `${TRANSLATION_NAMESPACE}.navigation.continue`
  },
  isLoading: undefined,
  dataQa: 'new-password-form'
};

export default NewPasswordForm;
