import React from 'react';
import { func } from 'prop-types';
import Link from '@nutkit/component-link';
import Notification, { notificationLevels } from '@nutkit/component-notification';
import Panel from '@nutkit/component-panel';
import { Heading, headingLevels } from '@nutkit/component-text';
import { useTranslation, Trans } from '@nm-utils-lib-web/translations';

import NewPasswordForm from '../NewPasswordForm';
import { passwordStates, SUPPORT_EMAIL } from '../../constants';
import { useGetCustomerPasswordRules, usePasswordReset } from '../../hooks';

const TRANSLATION_NAMESPACE = 'login.newPassword';

const NewPassword = ({ onResetPasswordSuccess }) => {
  const { t } = useTranslation();
  const { publicPasswordRules, privatePasswordRules, isLoading } = useGetCustomerPasswordRules();
  const { passwordState, putNewPasswordRequest, resetNewPasswordState } = usePasswordReset();
  const queryParams = new URLSearchParams(location.search);
  const urlParameterValue = queryParams.get('reset_password_token');

  if (passwordState === passwordStates.SUCCESS) {
    onResetPasswordSuccess && onResetPasswordSuccess();

    return null;
  }

  return (
    <>
      <Heading>{t(`${TRANSLATION_NAMESPACE}.heading`)}</Heading>
      {passwordState === passwordStates.FAILURE && (
        <Notification level={notificationLevels.ERROR} data-qa="new-password-error">
          <Trans
            components={[<Link href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</Link>]}
            i18nKey={`${TRANSLATION_NAMESPACE}.passwordResetFailed`}
          />
        </Notification>
      )}
      <Panel>
        <Heading level={headingLevels.TWO}>{t(`${TRANSLATION_NAMESPACE}.subHeading`)}</Heading>
        <NewPasswordForm
          token={urlParameterValue}
          putNewPasswordRequest={putNewPasswordRequest}
          publicPasswordRules={publicPasswordRules}
          data-qa="new-password-form"
          isFetching={isLoading}
          passwordState={passwordState}
          privatePasswordRules={privatePasswordRules}
          resetNewPasswordState={resetNewPasswordState}
        />
      </Panel>
    </>
  );
};

NewPassword.propTypes = {
  onResetPasswordSuccess: func.isRequired
};

export default NewPassword;
