import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { getEnvironment } from '@nm-utils-lib-web/environments';
import { FlagsProvider } from '@nm-utils-lib-web/flags';
import { monitor, apps, getAppName } from '@nm-utils-lib-web/monitoring';

import App from './components/App';

const appName = apps.NM_AUTH_WEB;
const environment = getEnvironment();
const isLocalEnvironment = environment === 'local';

monitor.init({
  app: getAppName(appName),
  disabled: isLocalEnvironment,
  environment,
  tracingSampleRate: parseFloat(window.NutmegConfig.SENTRY_SAMPLE_RATE),
  ...(!isLocalEnvironment && {
    release: `${appName}@${window.NutmegConfig.VERSION}`
  })
});

ReactDOM.render(
  <Router>
    <FlagsProvider>
      <App />
    </FlagsProvider>
  </Router>,
  document.getElementById('root')
);
